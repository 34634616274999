
import { defineComponent, ref, reactive } from 'vue';
import { FileItem } from '@/API/types';
import {
    submitInvoiceFile,
    submitLicenseFile,
} from '@/API/delayCallback/overdueSearch';
import { useStore } from 'vuex';
export default defineComponent({
    components: {},
    emits: ['close-sub-visible', 'get-modal-info'],
    props: {
        isSubVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        isInvoice: {
            type: Boolean,
            required: true,
            default: false,
        },
        isLicense: {
            type: Boolean,
            required: true,
            default: false,
        },
        selectInfo: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
    },
    setup(props, context) {
        const dealerCode = useStore().state.user.organization.entityCode;
        // 获取相关数据
        const modal = (props as any).isSubVisible;
        const invoiceVisible = ref<boolean>(false);
        invoiceVisible.value = (props as any).isInvoice;
        const licenseVisible = ref<boolean>(false);
        licenseVisible.value = (props as any).isLicense;
        const isLicenseVisible = ref<boolean>(false);
        const btnInfo = ref<string>('');
        if (invoiceVisible.value) {
            btnInfo.value = 'license';
        }
        if (licenseVisible.value) {
            btnInfo.value = 'invoice';
        }
        const data = (props as any).selectInfo;
        if (data.usage !== '经销商用车') {
            isLicenseVisible.value = true;
        }

        // 文件上传
        const invoiceUploadComplete = ref<boolean>(true);
        const invoiceFormData = new FormData();
        const invoiceFileName = ref<string>('');
        const invoiceFileSize = ref<string>('');
        const licenseFormData = new FormData();
        const licenseFileName = ref<string>('');
        const licenseFileSize = ref<string>('');
        const licenseUploadComplete = ref<boolean>(true);
        // 进度条
        const invoicePercent = ref<number>(0);
        const licensePercent = ref<number>(0);
        const invoiceContent = ref<string>('0%完成');
        const invoiceProStatus = ref<string>('normal');
        const licenseContent = ref<string>('0%完成');
        const licenseProStatus = ref<string>('normal');
        const isInvoiceSuccess = ref<boolean>(false);
        const isLicenseSuccess = ref<boolean>(false);
        const isSuccess = ref<boolean>(false);
        const invoiceConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (progressEvent.lengthComputable) {
                    const complete =
                        ((progressEvent.loaded / progressEvent.total) * 100) |
                        0;
                    invoicePercent.value = complete;
                    invoiceContent.value = complete + '%完成';
                }
            },
        };
        const licenseConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (progressEvent.lengthComputable) {
                    const complete =
                        ((progressEvent.loaded / progressEvent.total) * 100) |
                        0;
                    licensePercent.value = complete;
                    licenseContent.value = complete + '%完成';
                }
            },
        };
        const totalSubmit = ref<boolean>(true);
        const invoiceFileData = reactive([]);
        const licenseFileData = reactive([]);
        // 弹窗初始化
        const modalData = reactive({});
        const modalParams = reactive({
            noticeType: '',
            content: '',
            btnVisible: false,
        });

        const close = () => {
            context.emit('close-sub-visible', { isSubVisible: false });
        };

        const isSubmit = () => {
            const typePathData = sessionStorage.getItem('overdueFileSize');
            if (typePathData) {
                const typePath = parseFloat(typePathData);
                if (btnInfo.value == 'invoice') {
                    invoiceUploadComplete.value = false;
                    invoiceFileName.value = 'IN_' + data.vinNo + '.jpg';
                    invoiceFileSize.value =
                        (typePath / 1024 / 1024).toFixed(2) + 'MB';
                } else {
                    licenseUploadComplete.value = false;
                    licenseFileName.value = 'RE_' + data.vinNo + '.jpg';
                    licenseFileSize.value =
                        (typePath / 1024 / 1024).toFixed(2) + 'MB';
                }
                totalSubmit.value = true;
            }
        };

        const initCon = () => {
            isSubmit();
        };
        initCon();

        const beforeUpload = (file: FileItem, fileList: any) => {
            return new Promise((resolve, reject) => {
                const params = reactive({
                    noticeType: '',
                    content: '',
                    btnVisible: false,
                });
                const isJpeg =
                    file.name?.split('.')[1] === 'jpeg' ? true : false;
                const isJpg = file.type === 'image/jpeg';
                const isPdf = file.type === 'application/pdf';
                const isOfd = (file as any).name?.indexOf('.ofd') > -1;
                if ((!isJpg || isJpeg) && !isPdf && !isOfd) {
                    params.noticeType = 'error';
                    params.content =
                        '文件格式错误，请按照JPG PDF OFD等格式进行文件上传';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                    context.emit('get-modal-info', {
                        modalVisible: true,
                        modalData: modalData,
                    });
                    return reject(false);
                }
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                    params.noticeType = 'error';
                    params.content = '文件大小必须小于5MB!';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                    context.emit('get-modal-info', {
                        modalVisible: true,
                        modalData: modalData,
                    });
                    return reject(false);
                }
                const fileLength = fileList.length;
                if (fileLength > 1) {
                    params.noticeType = 'error';
                    params.content = '每次只能上传一张零售文件';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                    context.emit('get-modal-info', {
                        modalVisible: true,
                        modalData: modalData,
                    });
                    return reject(false);
                }
                return resolve(true);
            });
        };

        const invoiceCustomRequest = (source: any) => {
            invoiceFormData.append('file', source.file);
            invoiceFileName.value = source.file.name;
            invoiceFileSize.value =
                (source.file.size / 1024 / 1024).toFixed(2) + 'MB';
            invoiceUploadComplete.value = false;
            if (!licenseUploadComplete.value) {
                totalSubmit.value = false;
            }
            if (isLicenseVisible.value) {
                totalSubmit.value = false;
            }
        };

        const licenseCustomRequest = (source: any) => {
            licenseFormData.append('file', source.file);
            licenseFileName.value = source.file.name;
            licenseFileSize.value =
                (source.file.size / 1024 / 1024).toFixed(2) + 'MB';
            licenseUploadComplete.value = false;
            if (!invoiceUploadComplete.value) {
                totalSubmit.value = false;
            }
        };
        const submitInvoice = async () => {
            try {
                const res = await submitInvoiceFile(
                    invoiceConfig,
                    data.vinNo,
                    dealerCode,
                    invoiceFormData
                );
                if (res.code !== '0') {
                    isInvoiceSuccess.value = false;
                    invoiceProStatus.value = 'exception';
                    invoicePercent.value = 95;
                    invoiceFileSize.value = '';
                    invoiceContent.value = '失败';
                    modalParams.content = res.message;
                    totalSubmit.value = true;
                } else {
                    invoiceProStatus.value = 'normal';
                    modalParams.content = '上传成功！';
                    isInvoiceSuccess.value = true;
                    totalSubmit.value = false;
                }
            } catch (e) {
                isInvoiceSuccess.value = false;
                invoiceProStatus.value = 'exception';
                invoicePercent.value = 95;
                invoiceFileSize.value = '';
                modalParams.content = '上传失败';
                totalSubmit.value = true;
            }
        };
        const submitLicense = async () => {
            try {
                const res = await submitLicenseFile(
                    licenseConfig,
                    data.vinNo,
                    dealerCode,
                    licenseFormData
                );
                if (res.code !== '0') {
                    isLicenseSuccess.value = false;
                    licenseProStatus.value = 'exception';
                    licensePercent.value = 95;
                    licenseFileSize.value = '';
                    licenseContent.value = '失败';
                    totalSubmit.value = true;
                    modalParams.content = res.message;
                } else {
                    modalParams.content = '上传成功！';
                    licenseProStatus.value = 'normal';
                    isLicenseSuccess.value = true;
                    totalSubmit.value = false;
                }
            } catch (e) {
                isLicenseSuccess.value = false;
                licenseProStatus.value = 'exception';
                licensePercent.value = 95;
                licenseFileSize.value = '';
                licenseContent.value = '失败';
                totalSubmit.value = true;
                modalParams.content = '上传失败';
            }
        };
        const submitFile = async () => {
            const typePathData = sessionStorage.getItem('overdueFileSize');
            if (
                invoiceVisible.value &&
                licenseVisible.value &&
                !isLicenseVisible.value
            ) {
                totalSubmit.value = true;
                invoiceProStatus.value = 'normal';
                licenseProStatus.value = 'normal';
                if (typePathData) {
                    const typePath = parseFloat(typePathData);
                    if (btnInfo.value == 'invoice') {
                        isInvoiceSuccess.value = true;
                        invoiceProStatus.value = 'normal';
                        invoicePercent.value = 100;
                        modalParams.content = '上传成功！';
                        await submitLicense();
                    }
                    if (btnInfo.value == 'license') {
                        isLicenseSuccess.value = true;
                        licenseProStatus.value = 'normal';
                        licensePercent.value = 100;
                        modalParams.content = '上传成功！';
                        await submitInvoice();
                    }
                } else {
                    await submitInvoice();
                    await submitLicense();
                }
                if (isInvoiceSuccess.value && isLicenseSuccess.value) {
                    modalParams.noticeType = 'success';
                    modalParams.btnVisible = false;
                    close();
                } else {
                    modalParams.noticeType = 'error';
                    modalParams.btnVisible = false;
                }
            } else if (invoiceVisible.value) {
                totalSubmit.value = false;
                invoiceProStatus.value = 'normal';
                await submitInvoice();
                if (isInvoiceSuccess.value) {
                    modalParams.noticeType = 'success';
                    modalParams.btnVisible = false;
                    close();
                } else {
                    modalParams.noticeType = 'error';
                    modalParams.btnVisible = false;
                }
            } else if (licenseVisible.value) {
                totalSubmit.value = false;
                invoiceProStatus.value = 'normal';
                await submitLicense();
                if (isLicenseSuccess.value) {
                    modalParams.noticeType = 'success';
                    modalParams.btnVisible = false;
                    close();
                } else {
                    modalParams.noticeType = 'error';
                    modalParams.btnVisible = false;
                }
            }
            Object.assign(modalData, modalParams);
            context.emit('get-modal-info', {
                modalVisible: true,
                modalData: modalData,
            });
        };

        return {
            modal,
            invoiceVisible,
            licenseVisible,
            data,
            close,
            beforeUpload,
            invoiceUploadComplete,
            licenseUploadComplete,
            submitFile,
            submitInvoice,
            submitLicense,
            invoiceCustomRequest,
            licenseCustomRequest,
            invoicePercent,
            licensePercent,
            invoiceConfig,
            licenseConfig,
            invoiceProStatus,
            licenseProStatus,
            invoiceContent,
            licenseContent,
            totalSubmit,
            initCon,
            isInvoiceSuccess,
            isLicenseSuccess,
            isSuccess,
            modalData,
            invoiceFileData,
            licenseFileData,
            modalParams,
            isSubmit,
            invoiceFileName,
            invoiceFileSize,
            licenseFileName,
            licenseFileSize,
            isLicenseVisible,
        };
    },
});
