
import { defineComponent, ref, reactive, onBeforeMount } from 'vue';
import { FileItem } from '@/API/types';
import {
    batchInvoiceFile,
    batchLicenseFile,
    batchFileSubmit,
    batchSubmitResult,
} from '@/API/delayCallback/overdueSearch';
import moment from 'moment';
import downloadFile from '@/utils/claim/downloadFile';
import { guid } from '@/utils/index';
const columns = [
    {
        title: '车架号',
        dataIndex: 'vinNo',
        key: 'vinNo',
        width: 100,
    },
    {
        title: '文件名',
        dataIndex: 'fileName',
        key: 'fileName',
        width: 150,
    },
    {
        title: '提交结果',
        dataIndex: 'rtnMessage',
        key: 'rtnMessage',
        width: 150,
        slots: { customRender: 'rtnMessage' },
    },
];
export default defineComponent({
    components: {},
    emits: ['close-batch-visible', 'get-modal-info'],
    props: {
        isBatchVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        noLicense: {
            type: Boolean,
            required: true,
            default: false,
        },
        batchActiveKey: {
            type: String,
            required: true,
            default: '1',
        },
    },
    setup(props, context) {
        const modal = (props as any).isBatchVisible;
        const bActiveKey = ref<string>('');
        bActiveKey.value = (props as any).batchActiveKey;
        // tab标签可否编辑
        const invoiceDisabled = ref<boolean>(false);
        const licenseDisabled = ref<boolean>(false);
        licenseDisabled.value = (props as any).noLicense;
        // 文件上传
        const invoiceUploadComplete = ref<boolean>(true);
        const invoiceFormData = new FormData();
        const invoiceFile = ref([]);
        const invoiceCount = ref<number>(0);
        const invoiceFileSize = ref<string>('');
        const licenseFormData = new FormData();
        const licenseFile = ref([]);
        const licenseCount = ref<number>(0);
        const licenseFileSize = ref<string>('');
        const licenseUploadComplete = ref<boolean>(true);
        // 上传进度条
        const invoicePercent = ref<number>(0);
        const licensePercent = ref<number>(0);
        const invoiceFileNum = ref<number>(0);
        const licenseFileNum = ref<number>(0);
        const invoiceContent = ref<string>('0/0');
        const invoiceProStatus = ref<string>('normal');
        const licenseContent = ref<string>('0/0');
        const licenseProStatus = ref<string>('normal');
        const isInvoiceSuccess = ref<boolean>(false);
        const isLicenseSuccess = ref<boolean>(false);
        const closeCount = ref<number>(0);
        // 智传发票进度条
        const invoiceConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (progressEvent.lengthComputable) {
                    const complete =
                        ((progressEvent.loaded / progressEvent.total) * 100) |
                        0;
                    invoicePercent.value = complete;
                    invoiceContent.value =
                        Math.floor(
                            (progressEvent.loaded / progressEvent.total) *
                                invoiceFileNum.value
                        ) +
                        '/' +
                        invoiceFileNum.value +
                        '（' +
                        complete +
                        '%完成）';
                }
            },
        };
        // 智传行驶证进度条
        const licenseConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (progressEvent.lengthComputable) {
                    const complete =
                        ((progressEvent.loaded / progressEvent.total) * 100) |
                        0;
                    licensePercent.value = complete;
                    licenseContent.value =
                        Math.floor(
                            (progressEvent.loaded / progressEvent.total) *
                                licenseFileNum.value
                        ) +
                        '/' +
                        licenseFileNum.value +
                        '（' +
                        complete +
                        '%完成）';
                }
            },
        };
        const totalSubmit = ref<boolean>(true);
        const tempInvoiceData = ref([]);
        const tempLicenseData = ref([]);
        // 弹窗初始化
        const modalData = reactive({});
        const modalParams = reactive({
            noticeType: '',
            content: '',
            btnVisible: false,
        });
        const invoiceTit = ref<string>('文件上传');
        const licenseTit = ref<string>('文件上传');
        const isSubmit = ref<boolean>(true);
        const invoiceSessionData = sessionStorage.getItem('overdueInvoiceData');
        const licenseSessionData = sessionStorage.getItem('overdueLicenseData');
        const dataSource = ref({
            submitResult: {
                content: [],
            },
        });
        const isContinue = ref<boolean>(false);
        const current = ref<number>(1);
        const size = ref<number>(20);
        const invoiceSubCount = ref<number>(0);
        const licenseSubCount = ref<number>(0);
        const invoiceClickCount = ref<number>(0);
        const licenseClickCount = ref<number>(0);
        const invoiceKey = ref<string>(guid());
        const licenseKey = ref<string>(guid());
        const uploadError = ref<boolean>(false);
        const timer = ref<number>(0);
        const isSame = ref<boolean>(false);

        // 关闭弹窗
        const close = () => {
            closeCount.value = 1;
            const continueValue = sessionStorage.getItem('overdueContinue');
            clearInterval(timer.value);
            timer.value = 0;
            if (invoiceClickCount.value == 1 || licenseClickCount.value == 1) {
                if (invoiceClickCount.value == 1) {
                    if (!isInvoiceSuccess.value && !uploadError.value) {
                        modalParams.noticeType = 'warning';
                        modalParams.content =
                            '正在批量上传，此操作会取消该批次文件的全部上传，确定要关闭窗口？';
                        modalParams.btnVisible = true;
                        Object.assign(modalData, modalParams);
                        sessionStorage.setItem(
                            'stopOverdueInvoice',
                            invoiceKey.value
                        );
                        context.emit('get-modal-info', {
                            modalVisible: true,
                            modalData: modalData,
                        });
                    } else {
                        sessionStorage.setItem('stopOverdueInvoice', '');
                        context.emit('close-batch-visible', {
                            isBatchVisible: false,
                        });
                    }
                }
                if (licenseClickCount.value == 1) {
                    if (!isLicenseSuccess.value && !uploadError.value) {
                        modalParams.noticeType = 'warning';
                        modalParams.content =
                            '正在批量上传，此操作会取消该批次文件的全部上传，确定要关闭窗口？';
                        modalParams.btnVisible = true;
                        Object.assign(modalData, modalParams);
                        sessionStorage.setItem(
                            'stopOverdueLicense',
                            licenseKey.value
                        );
                        context.emit('get-modal-info', {
                            modalVisible: true,
                            modalData: modalData,
                        });
                    } else {
                        sessionStorage.setItem('stopOverdueLicense', '');
                        context.emit('close-batch-visible', {
                            isBatchVisible: false,
                        });
                    }
                }
            } else {
                context.emit('close-batch-visible', { isBatchVisible: false });
            }
        };

        // 获取提交结果
        const getBatchSubmitResult = async (batchNo: string) => {
            const params = {
                batchNo: batchNo,
                current: current.value,
                size: size.value,
            };
            await batchSubmitResult({ params }).then((res: any) => {
                dataSource.value = res;
            });
        };
        // 提交
        const batchSub = async (type: string, batchNo: string) => {
            const params = {
                batchNo: batchNo,
            };
            isContinue.value = false;
            dataSource.value = {
                submitResult: {
                    content: [],
                },
            };
            try {
                const res = await batchFileSubmit({ params });
                if (res.code !== '0') {
                    if (type == 'invoice') {
                        invoiceProStatus.value = 'exception';
                        invoiceContent.value = '提交失败';
                    } else {
                        licenseProStatus.value = 'exception';
                        licenseContent.value = '提交失败';
                    }
                    clearInterval(timer.value);
                    timer.value = 0;
                    modalParams.content = res.message;
                } else {
                    const useCurrent = res.data.current;
                    const useTotal = res.data.total;
                    const percentage = ((useCurrent / useTotal) * 100) | 0;
                    if (type == 'invoice') {
                        invoiceProStatus.value = 'normal';
                        invoicePercent.value = percentage;
                        invoiceContent.value =
                            useCurrent +
                            '/' +
                            useTotal +
                            '（' +
                            invoicePercent.value +
                            '%完成）';
                    } else {
                        licenseProStatus.value = 'normal';
                        licensePercent.value = percentage;
                        licenseContent.value =
                            useCurrent +
                            '/' +
                            useTotal +
                            '（' +
                            licensePercent.value +
                            '%完成）';
                    }
                    if (useCurrent !== 0 && useCurrent == useTotal) {
                        isSame.value = true;
                        clearInterval(timer.value);
                        timer.value = 0;
                        const activeValue = ref('');
                        if (bActiveKey.value == '1') {
                            // licenseDisabled.value = false; //todo 很有可能还会出现问题，批量行驶证提交的可点击逻辑不确定，story和别人写的代码对不上
                            activeValue.value = 'invoice';
                        } else {
                            invoiceDisabled.value = false;
                            activeValue.value = 'license';
                        }
                        if (type !== activeValue.value) {
                            isContinue.value = false;
                            dataSource.value = {
                                submitResult: {
                                    content: [],
                                },
                            };
                        } else {
                            await getBatchSubmitResult(batchNo);
                            isContinue.value = true;
                        }
                    } else {
                        if (percentage == 100) {
                            modalParams.content = '提交成功！';
                        }
                    }
                }
            } catch (e) {
                if (type == 'invoice') {
                    invoiceProStatus.value = 'exception';
                    invoiceContent.value = '提交失败';
                } else {
                    licenseProStatus.value = 'exception';
                    licenseContent.value = '提交失败';
                }
                clearInterval(timer.value);
                timer.value = 0;
                modalParams.content = '提交失败';
            }
        };

        // 相关操作刷新方法
        const initCon = async () => {
            // 初始化先清空计时器
            clearInterval(timer.value);
            timer.value = 0;
            isContinue.value = false;
            dataSource.value = {
                submitResult: {
                    content: [],
                },
            };
            // 获取存储数据看是否请求过相关接口逻辑
            const invoiceSessionData = sessionStorage.getItem(
                'overdueInvoiceData'
            );
            const licenseSessionData = sessionStorage.getItem(
                'overdueLicenseData'
            );
            const invoiceIsSuccess = sessionStorage.getItem(
                'overdueInvoiceSuccess'
            );
            const licenseIsSuccess = sessionStorage.getItem(
                'overdueLicenseSuccess'
            );
            const isInvoiceContinueValue = sessionStorage.getItem(
                'isOverdueInvoiceContinue'
            );
            const isLicenseContinueValue = sessionStorage.getItem(
                'isOverdueLicenseContinue'
            );
            if (bActiveKey.value == '1') {
                if (invoiceSessionData) {
                    if (invoiceIsSuccess) {
                        return;
                    } else {
                        const invoiceSession = JSON.parse(invoiceSessionData);
                        if (isInvoiceContinueValue) {
                            // 提交过调取存储的数据拿到key值调用提交进度接口
                            invoiceUploadComplete.value = false;
                            invoiceTit.value = '提交进度';
                            isSubmit.value = false;
                            invoiceFileSize.value = '';
                            invoiceKey.value = invoiceSession.invoiceKey;
                            await batchSub('invoice', invoiceKey.value);
                            if (!isSame.value) {
                                timer.value = setInterval(async () => {
                                    await batchSub('invoice', invoiceKey.value);
                                }, 4000);
                            } else {
                                isSame.value = false;
                            }
                        } else {
                            invoiceUploadComplete.value = false;
                            invoiceTit.value = '提交进度';
                            isSubmit.value = false;
                            invoicePercent.value = 0;
                            invoiceContent.value =
                                '0/' +
                                invoiceSession.invoiceFileNum +
                                '（0%完成）';
                            invoiceFileSize.value = '';
                        }
                    }
                }
            } else {
                if (licenseSessionData) {
                    if (licenseIsSuccess) {
                        return;
                    } else {
                        const licenseSession = JSON.parse(licenseSessionData);
                        if (isLicenseContinueValue) {
                            // 提交过调取存储的数据拿到key值调用提交进度接口
                            licenseUploadComplete.value = false;
                            licenseTit.value = '提交进度';
                            isSubmit.value = false;
                            licenseFileSize.value = '';
                            licenseKey.value = licenseSession.licenseKey;
                            await batchSub('license', licenseKey.value);
                            if (!isSame.value) {
                                timer.value = setInterval(async () => {
                                    await batchSub('license', licenseKey.value);
                                }, 4000);
                            } else {
                                isSame.value = false;
                            }
                        } else {
                            licenseUploadComplete.value = false;
                            licenseTit.value = '提交进度';
                            isSubmit.value = false;
                            licensePercent.value = 0;
                            licenseContent.value =
                                '0/' +
                                licenseSession.licenseFileNum +
                                '（0%完成）';
                            licenseFileSize.value = '';
                        }
                    }
                }
            }
        };

        initCon();

        // tab切换
        const changeTab = (activeKey: string) => {
            // 切换先清空计时器
            clearInterval(timer.value);
            timer.value = 0;
            bActiveKey.value = activeKey;
            isContinue.value = false;
            dataSource.value = {
                submitResult: {
                    content: [],
                },
            };
            if (activeKey == '1') {
                if (invoiceFile.value.length > 0) {
                    totalSubmit.value = false;
                } else {
                    totalSubmit.value = true;
                }
                const invoiceDataValue = sessionStorage.getItem(
                    'overdueInvoiceData'
                );
                if (invoiceDataValue) {
                    isSubmit.value = false;
                } else {
                    isSubmit.value = true;
                }
                const isInvoiceContinueValue = sessionStorage.getItem(
                    'isOverdueInvoiceContinue'
                );
                if (isInvoiceContinueValue) {
                    invoiceUploadComplete.value = false;
                    invoiceTit.value = '提交进度';
                    isSubmit.value = false;
                    invoicePercent.value = 0;
                    if (invoiceDataValue) {
                        const invoiceSession = JSON.parse(invoiceDataValue);
                        invoiceContent.value =
                            '0/' + invoiceSession.invoiceFileNum + '（0%完成）';
                    }
                    invoiceFileSize.value = '';
                }
            } else {
                if (licenseFile.value.length > 0) {
                    totalSubmit.value = false;
                } else {
                    totalSubmit.value = true;
                }
                const licenseDataValue = sessionStorage.getItem(
                    'overdueLicenseData'
                );
                if (licenseDataValue) {
                    isSubmit.value = false;
                } else {
                    isSubmit.value = true;
                }
                const isLicenseContinueValue = sessionStorage.getItem(
                    'isOverdueLicenseContinue'
                );
                if (isLicenseContinueValue) {
                    licenseUploadComplete.value = false;
                    licenseTit.value = '提交进度';
                    isSubmit.value = false;
                    licensePercent.value = 0;
                    if (licenseDataValue) {
                        const licenseSession = JSON.parse(licenseDataValue);
                        licenseContent.value =
                            '0/' + licenseSession.licenseFileNum + '（0%完成）';
                    }
                    licenseFileSize.value = '';
                }
            }
            initCon();
        };

        // 本地上传校验
        const beforeUpload = (file: FileItem, fileList: any) => {
            return new Promise((resolve, reject) => {
                const params = reactive({
                    noticeType: '',
                    content: '',
                    btnVisible: false,
                });
                const isJpeg =
                    file.name?.split('.')[1] === 'jpeg' ? true : false;
                const isJpg = file.type === 'image/jpeg';
                const isPdf = file.type === 'application/pdf';
                const isOfd = (file as any).name?.indexOf('.ofd') > -1;
                // 判断是否是JPG PDF OFD格式
                if ((!isJpg || isJpeg) && !isPdf && !isOfd) {
                    params.noticeType = 'error';
                    params.content =
                        '文件格式错误，请按照JPG PDF OFD等格式进行文件上传';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                    context.emit('get-modal-info', {
                        modalVisible: true,
                        modalData: modalData,
                    });
                    if (bActiveKey.value == '1') {
                        invoiceCount.value = 1;
                    } else {
                        licenseCount.value = 1;
                    }
                    return reject(false);
                }
                // 判断图片大小
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                    params.noticeType = 'error';
                    params.content = '文件大小必须小于5MB!';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                    context.emit('get-modal-info', {
                        modalVisible: true,
                        modalData: modalData,
                    });
                    if (bActiveKey.value == '1') {
                        invoiceCount.value = 1;
                    } else {
                        licenseCount.value = 1;
                    }
                    return reject(false);
                }
                // 判断图片数量
                const fileLength = fileList.length;
                // if (fileLength > 30) {
                //   params.noticeType = 'warning';
                //   params.content = '建议单次批量智传30张及以下文件数量为佳，是否继续上传？';
                //   params.btnVisible = true;
                //   Object.assign(modalData, params);
                //   context.emit('get-modal-info', {modalVisible: true, modalData: modalData});
                //   const continueWord = sessionStorage.getItem("modalContinue");
                //   if (continueWord == 'false') {
                //     return reject(false);
                //   }
                // }
                if (fileLength > 100) {
                    params.noticeType = 'error';
                    params.content = '文件数量不能大于100张';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                    context.emit('get-modal-info', {
                        modalVisible: true,
                        modalData: modalData,
                    });
                    if (bActiveKey.value == '1') {
                        invoiceCount.value = 1;
                    } else {
                        licenseCount.value = 1;
                    }
                    return reject(false);
                }
                // 如果满足上述条件 则给对应的对象赋值
                if (bActiveKey.value == '1') {
                    invoiceFile.value = fileList;
                    tempInvoiceData.value = fileList;
                    // Object.assign(tempInvoiceData, fileList);
                } else {
                    licenseFile.value = fileList;
                    tempLicenseData.value = fileList;
                    // Object.assign(tempLicenseData, fileList);
                }
                return resolve(true);
            });
        };

        // 重写默认上传方法 获取合规的发票图片信息
        const invoiceCustomRequest = (source: any) => {
            if (invoiceCount.value !== 1) {
                const size = ref<number>(0);
                const params = reactive({
                    noticeType: '',
                    content: '',
                    btnVisible: false,
                });
                invoiceFile.value.forEach((ele: any, index: number) => {
                    size.value = size.value + ele.size;
                });
                invoiceFileNum.value = invoiceFile.value.length;
                invoiceContent.value =
                    '0/' + invoiceFileNum.value + '（0%完成）';
                invoiceFileSize.value =
                    (size.value / 1024 / 1024).toFixed(2) + 'MB';
                invoiceUploadComplete.value = false;
                totalSubmit.value = false;
            }
        };

        // 重写默认上传方法 获取合规的行驶证图片信息
        const licenseCustomRequest = (source: any) => {
            if (licenseCount.value !== 1) {
                const size = ref<number>(0);
                const params = reactive({
                    noticeType: '',
                    content: '',
                    btnVisible: false,
                });
                licenseFile.value.forEach((ele: any, index: number) => {
                    size.value = size.value + ele.size;
                });
                licenseFileNum.value = licenseFile.value.length;
                licenseContent.value =
                    '0/' + licenseFileNum.value + '（0%完成）';
                licenseFileSize.value =
                    (size.value / 1024 / 1024).toFixed(2) + 'MB';
                licenseUploadComplete.value = false;
                totalSubmit.value = false;
            }
        };
        // 上传发票
        const batchInvoice = async () => {
            try {
                const res = await batchInvoiceFile(
                    invoiceConfig,
                    invoiceKey.value,
                    invoiceFormData
                );
                // 判断返回的code值 除0外都是异常
                if (res.code !== '0') {
                    isInvoiceSuccess.value = false;
                    invoiceProStatus.value = 'exception';
                    invoiceContent.value = '上传失败';
                    uploadError.value = true;
                    modalParams.content = res.message;
                } else {
                    modalParams.content =
                        '当前批次已上传完成将进入到系统自动批量提交阶段，关闭窗口后可在任务中心中关注后续系统提交进程及结果。';
                    invoiceProStatus.value = 'normal';
                    isInvoiceSuccess.value = true;
                }
            } catch (e) {
                isInvoiceSuccess.value = false;
                uploadError.value = true;
                invoiceProStatus.value = 'exception';
                invoiceContent.value = '上传失败';
                modalParams.content = '上传失败';
            }
        };
        // 上传行驶证
        const batchLicense = async () => {
            try {
                const res = await batchLicenseFile(
                    licenseConfig,
                    licenseKey.value,
                    licenseFormData
                );
                // 判断返回的code值 除0外都是异常
                if (res.code !== '0') {
                    isLicenseSuccess.value = false;
                    licenseProStatus.value = 'exception';
                    licenseContent.value = '上传失败';
                    uploadError.value = true;
                    modalParams.content = res.message;
                } else {
                    modalParams.content =
                        '当前批次已上传完成将进入到系统自动批量提交阶段，关闭窗口后可在任务中心中关注后续系统提交进程及结果。';
                    licenseProStatus.value = 'normal';
                    isLicenseSuccess.value = true;
                }
            } catch (e) {
                isLicenseSuccess.value = false;
                uploadError.value = true;
                licenseProStatus.value = 'exception';
                licenseContent.value = '上传失败';
                modalParams.content = '上传失败';
            }
        };
        // 上传报错 关闭弹窗 重置相应数据
        const isResetModal = (param: { continue: boolean }) => {
            const isCloseModal = param.continue;
            if (isCloseModal) {
                if (invoiceProStatus.value == 'exception') {
                    invoiceUploadComplete.value = true;
                    totalSubmit.value = true;
                    invoiceProStatus.value = 'normal';
                    invoicePercent.value = 0;
                    tempInvoiceData.value = [];
                    invoiceFormData.delete('files');
                }
                if (licenseProStatus.value == 'exception') {
                    licenseUploadComplete.value = true;
                    totalSubmit.value = true;
                    licenseProStatus.value = 'normal';
                    licensePercent.value = 0;
                    tempLicenseData.value = [];
                    licenseFormData.delete('files');
                }
            }
        };
        // 图片格式错误 再次上传清空之前记录数
        const isResetCount = (param: { continue: boolean }) => {
            const resetCount = param.continue;
            if (resetCount) {
                if (bActiveKey.value == '1') {
                    invoiceCount.value = 0;
                } else {
                    licenseCount.value = 0;
                }
            }
        };
        // 提交
        const batchSubmit = async () => {
            if (bActiveKey.value == '1') {
                licenseDisabled.value = true;
                // 计时器每4秒请求一次提交进度接口 调用进度 刷新进度样式
                timer.value = setInterval(async () => {
                    await batchSub('invoice', invoiceKey.value);
                }, 4000);
                sessionStorage.setItem('isOverdueInvoiceContinue', 'invoice');
            } else {
                invoiceDisabled.value = true;
                // 计时器每4秒请求一次提交进度接口 调用进度 刷新进度样式
                timer.value = setInterval(async () => {
                    await batchSub('license', licenseKey.value);
                }, 4000);
                sessionStorage.setItem('isOverdueLicenseContinue', 'license');
            }
        };
        // 智传
        const submitFile = async () => {
            totalSubmit.value = true;
            if (bActiveKey.value == '1') {
                licenseDisabled.value = true;
                // 清空之前请求存储数据
                sessionStorage.setItem('overdueInvoiceSuccess', '');
                sessionStorage.setItem('isOverdueInvoiceContinue', '');
                invoiceClickCount.value = 1;
                // 转换上传数据格式
                (tempInvoiceData as any).value.forEach(
                    (ele: any, index: number) => {
                        invoiceFormData.append('files', ele);
                    }
                );
                invoiceProStatus.value = 'normal';
                await batchInvoice();
                // 判断上传是否成功 进行相关处理
                if (isInvoiceSuccess.value) {
                    modalParams.noticeType = 'warning';
                    modalParams.btnVisible = false;
                    invoiceFileSize.value = '';
                    // 传递相关需要保存的数据
                    const invoiceSession = reactive({
                        invoiceData: tempInvoiceData,
                        isInvoiceSuccess: true,
                        invoiceFileNum: invoiceFileNum,
                        invoiceFileSize: invoiceFileSize,
                        invoiceKey: invoiceKey,
                    });
                    totalSubmit.value = true;
                    // 获取是否取消了上传 若取消了 则清空相关存储数据 若未取消则存储相关数据
                    const invoiceIsSuccess = sessionStorage.getItem(
                        'overdueInvoiceSuccess'
                    );
                    if (invoiceIsSuccess) {
                        sessionStorage.setItem('overdueInvoiceData', '');
                    } else {
                        sessionStorage.setItem(
                            'overdueInvoiceData',
                            JSON.stringify(invoiceSession)
                        );
                    }
                    // 上传成功调用提交接口
                    batchSubmit();
                } else {
                    modalParams.noticeType = 'error';
                    modalParams.btnVisible = false;
                    sessionStorage.setItem('overdueInvoiceData', '');
                }
                licenseDisabled.value = (props as any).noLicense && false;
            } else {
                invoiceDisabled.value = true;
                licenseProStatus.value = 'normal';
                // 清空之前请求存储数据
                sessionStorage.setItem('overdueLicenseSuccess', '');
                sessionStorage.setItem('isOverdueLicenseContinue', '');
                licenseClickCount.value = 1;
                // 转换上传数据格式
                (tempLicenseData as any).value.forEach(
                    (ele: any, index: number) => {
                        licenseFormData.append('files', ele);
                    }
                );
                await batchLicense();
                // 判断上传是否成功 进行相关处理
                if (isLicenseSuccess.value) {
                    modalParams.noticeType = 'warning';
                    modalParams.btnVisible = false;
                    licenseFileSize.value = '';
                    // 传递相关需要保存的数据
                    const licenseSession = reactive({
                        licenseData: tempLicenseData,
                        isLicenseSuccess: true,
                        licenseFileNum: licenseFileNum,
                        licenseFileSize: licenseFileSize,
                        licenseKey: licenseKey,
                    });
                    totalSubmit.value = true;
                    // 获取是否取消了上传 若取消了 则清空相关存储数据 若未取消则存储相关数据
                    const licenseIsSuccess = sessionStorage.getItem(
                        'overdueLicenseSuccess'
                    );
                    if (licenseIsSuccess) {
                        sessionStorage.setItem('overdueLicenseData', '');
                    } else {
                        sessionStorage.setItem(
                            'overdueLicenseData',
                            JSON.stringify(licenseSession)
                        );
                    }
                    // 上传成功调用提交接口
                    batchSubmit();
                } else {
                    modalParams.noticeType = 'error';
                    modalParams.btnVisible = false;
                    sessionStorage.setItem('overdueLicenseData', '');
                }
                invoiceDisabled.value = false;
            }
            // 判断是否点击了打断按钮
            const continueValue = sessionStorage.getItem('overdueContinue');
            if (closeCount.value == 0 || continueValue !== 'true') {
                Object.assign(modalData, modalParams);
                context.emit('get-modal-info', {
                    modalVisible: true,
                    modalData: modalData,
                });
            } else {
                closeCount.value = 0;
            }
            initCon();
        };
        // 分页逻辑
        const pageChange = (page: number, pageSize: number) => {
            current.value = page > 0 ? page : 1;
            size.value = pageSize;
            if (bActiveKey.value == '1') {
                getBatchSubmitResult(invoiceKey.value);
            } else {
                getBatchSubmitResult(licenseKey.value);
            }
        };
        const sizeChange = (page: number, pageSize: number) => {
            current.value = page > 0 ? page : 1;
            size.value = pageSize;
            if (bActiveKey.value == '1') {
                getBatchSubmitResult(invoiceKey.value);
            } else {
                getBatchSubmitResult(licenseKey.value);
            }
        };
        // 继续上传 清空相关数据
        const goOnUpload = () => {
            if (bActiveKey.value == '1') {
                invoiceUploadComplete.value = true;
                invoiceTit.value = '文件上传';
                sessionStorage.setItem('overdueInvoiceData', '');
                sessionStorage.setItem('isOverdueInvoiceContinue', '');
                invoicePercent.value = 0;
                invoiceKey.value = guid();
                invoiceSubCount.value = 0;
                invoiceFormData.delete('files');
            } else {
                licenseUploadComplete.value = true;
                licenseTit.value = '文件上传';
                licensePercent.value = 0;
                licenseKey.value = guid();
                licenseSubCount.value = 0;
                licenseFormData.delete('files');
                sessionStorage.setItem('overdueLicenseData', '');
                sessionStorage.setItem('isOverdueLicenseContinue', '');
            }
            isContinue.value = false;
            dataSource.value = {
                submitResult: {
                    content: [],
                },
            };
            isSubmit.value = true;
            totalSubmit.value = true;
        };
        // 导出提交结果
        const exportSubmitEvent = () => {
            // 目前是batchNo写死的 假数据
            const batchNo = ref<string>('');
            if (bActiveKey.value == '1') {
                batchNo.value = invoiceKey.value;
            } else {
                batchNo.value = licenseKey.value;
            }
            const params = {
                url:
                    `/rvapi/basic/apply/dealer/submitResultExport?batchNo=` +
                    batchNo.value,
                method: 'post',
                params: {},
                fileName: `${moment(new Date()).format('YYYYMMDDhhmmss')}.xlsx`,
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };

        onBeforeMount(() => {
            clearInterval(timer.value);
            timer.value = 0;
        });

        return {
            bActiveKey,
            modal,
            close,
            beforeUpload,
            invoiceUploadComplete,
            licenseUploadComplete,
            submitFile,
            batchInvoice,
            batchLicense,
            invoiceCustomRequest,
            licenseCustomRequest,
            invoiceFile,
            licenseFile,
            invoicePercent,
            licensePercent,
            invoiceConfig,
            licenseConfig,
            invoiceProStatus,
            licenseProStatus,
            invoiceContent,
            licenseContent,
            totalSubmit,
            isInvoiceSuccess,
            isLicenseSuccess,
            modalData,
            changeTab,
            invoiceCount,
            licenseCount,
            invoiceFileSize,
            licenseFileSize,
            invoiceFileNum,
            licenseFileNum,
            tempInvoiceData,
            tempLicenseData,
            closeCount,
            modalParams,
            initCon,
            invoiceTit,
            licenseTit,
            batchSubmit,
            isSubmit,
            isContinue,
            columns,
            current,
            size,
            pageChange,
            sizeChange,
            dataSource,
            invoiceSubCount,
            licenseSubCount,
            invoiceClickCount,
            licenseClickCount,
            invoiceKey,
            licenseKey,
            exportSubmitEvent,
            goOnUpload,
            isResetModal,
            isResetCount,
            batchSub,
            timer,
            getBatchSubmitResult,
            invoiceDisabled,
            licenseDisabled,
            isSame,
        };
    },
});
